import React from "react";
import { Tween, Reveal } from "react-gsap";
import Link from "../Global/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImagePremierInn from "../../images/Hotel-PremierInn.png";
import ImageVillageHotel from "../../images/Hotel-VillageHotel.png";
import ImageTheQueens from "../../images/Hotel-TheQueens.png";

const CardMusic = (props) => {
  const { image, title, label, time, description, link } = props;

  return (
    <div className="brand-background-white p-3 rounded text-center h-100">
      <img src={image} alt={title} className="rounded w-100 mb-3" />
      <h4 className="brand-font brand-responsive">{title}</h4>
      <h5 className="brand-font">{label}</h5>
      <p>{time}</p>
      <p>{description}</p>
    </div>
  );
};

export default CardMusic;
