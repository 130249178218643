import React from "react";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Hero from "../components/Templates/Hero";
import CardMusic from "../components/Cards/CardMusic";
import ImageUniverse from "../images/Lineup-Universe.png";
import ImageHatfieldBros from "../images/Lineup-HatfieldBros.png";
import ImageTopTierBrass from "../images/Lineup-TopTierBrass.png";
import ImageMollyPukes from "../images/Lineup-MollyPukes.png";
import ImageMattiello from "../images/Lineup-Mattiello.png";
import ImageDukesPizza from "../images/Lineup-DukesPizza.png";
import ImageElKantina from "../images/Lineup-ElKantina.png";

// Markup
const Lineup = () => {
  const openGraph = {
    type: "website",
    title: "Line-Up | CP4RP",
    socialTitle: "All the details for Claire & Richy's big day",
    description: "20th May 2023 | Left Bank, Leeds",
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} />
      <Hero title="Line-Up" colour="pink" />
      <section className="py-5 brand-background-black">
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col-12 brand-text-white text-center">
              <h2 className="brand-font brand-responsive">
                Saturday 20th May 2023
              </h2>
              <h3 className="brand-font brand-responsive">Left Bank Leeds</h3>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-4 order-lg-1">
              <CardMusic
                image={ImageUniverse}
                title="Universe"
                label="80s rock party band from Yorkshire"
                time="9pm"
                description="Hot off the scene in Huddersfield, Richy's hometown friends are showing us what rock gods look like in real life..."
                instagram="https://www.instagram.com/universepartyband"
              />
            </div>

            <div className="col-12 col-lg-4 mt-5 order-lg-2">
              <CardMusic
                image={ImageHatfieldBros}
                title="Hatfield Bros"
                label="Ibiza DJs"
                time="11pm"
                description="Two devilishly handsome DJs (it must run in the family) will be seeing us out, spinning Ibiza Classics"
              />
            </div>

            <div className="col-12 col-lg-4 mt-5 order-lg-0">
              <CardMusic
                image={ImageTopTierBrass}
                title="Top Tier Brass"
                label="Re-imagined pop classics"
                time="2pm"
                description="From anthems by Beyoncé to classic tracks by The Beach Boys"
              />
            </div>
          </div>
          <div className="row py-5 justify-content-center">
            <div className="col-12 brand-text-white text-center">
              <p className="fs-3 mb-0">Supported by</p>
            </div>
          </div>

          <div className="row justify-content-center mb-4 gy-4">
            <div className="col-12 col-lg-4">
              <CardMusic
                image={ImageMattiello}
                title="Magician"
                label="Table Magic"
                time="4pm onwards"
                description="Table magic and mentalist. Francesco will be entertaining the crowd throughout dinner, followed by a session for the kids to learn magic tricks too!"
              />
            </div>

            <div className="col-12 col-lg-4">
              <CardMusic
                image={ImageMollyPukes}
                title="Molly Pukes"
                label="Illustrator"
                time="All day"
                description="MollyPukes is a local artist, famously illustrating Tracy Beaker, who will be drawing portraits all day! Singles, couples and family portraits all welcome!"
                instagram="https://www.instagram.com/mollypukes/"
              />
            </div>
          </div>

          <div className="row justify-content-center gy-4">
            <div className="col-12 col-lg-4">
              <CardMusic
                image={ImageElKantina}
                title="El Kantina"
                label="Mexican Fiesta"
                time="3:30pm-ish"
                description="Mexican and Southern inspired food catering"
                instagram="https://www.instagram.com/elkantina"
                facebook="https://www.facebook.com/pages/El-Kantina/214945888611389"
                twitter="https://twitter.com/elkantina"
                website="https://www.elkantina.co.uk/"
              />
            </div>

            <div className="col-12 col-lg-4">
              <CardMusic
                image={ImageDukesPizza}
                title="Dukes Pizza"
                label="Wood-fired Pizzza Van"
                time="8pm-ish"
                description="A 1972 Californian van kitted out with a wood-fired pizza oven, serving awesome homemade pizzas"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Lineup;
